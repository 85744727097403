@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .scrollbar-thin {
    scrollbar-width: thin;
  }

  /* Mundial font-face */
  @font-face {
    font-family: 'Mundial';
    src: url('/fonts/Mundial/MundialBlack.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Mundial';
    src: url('/fonts/Mundial/MundialBlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Mundial';
    src: url('/fonts/Mundial/MundialBold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Mundial';
    src: url('/fonts/Mundial/MundialBoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Mundial';
    src: url('/fonts/Mundial/MundialDemibold.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Mundial';
    src: url('/fonts/Mundial/MundialDemiboldItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Mundial';
    src: url('/fonts/Mundial/MundialRegular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Mundial';
    src: url('/fonts/Mundial/MundialRegularItalic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Mundial';
    src: url('/fonts/Mundial/MundialLight.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Mundial';
    src: url('/fonts/Mundial/MundialLightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Mundial';
    src: url('/fonts/Mundial/MundialThin.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Mundial';
    src: url('/fonts/Mundial/MundialThinItalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Mundial';
    src: url('/fonts/Mundial/MundialHair.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Mundial';
    src: url('/fonts/Mundial/MundialHairItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
  }

  /* Jetbrains Mono font-face */
  @font-face {
    font-family: 'JetBrains Mono';
    src: url('/fonts/JetBrainsMono/JetBrainsMono-VariableFont.ttf') format('truetype');
    font-style: normal;
    font-weight: 1 999;
    font-display: swap;
  }
  @font-face {
    font-family: 'JetBrains Mono';
    src: url('/fonts/JetBrainsMono/JetBrainsMono-Italic-VariableFont.ttf') format('truetype');
    font-style: italic;
    font-weight: 1 999;
    font-display: swap;
  }
}